const rootUrl = 'https://api.portal.mdcn.gov.ng';
const remitaVerificationCallBackUrl = 'https://hospital.portal.mdcn.gov.ng/verification-callback';
// user module Urls
const getHospitalsUrl = rootUrl+'/get-hospitals';
const createSignupUrl = rootUrl+'/hospital-register';
const hospitalLoginUrl = rootUrl+'/hospital-login';
const hospitalProfileUrl = rootUrl+'/get-hospital-profile';
const changePasswordUrl = rootUrl+'/change-hospital-password';
const uploadUserPhotoUrl = rootUrl+'/upload-hospital-photo';
const getHospitalNotificationsUrl = rootUrl+'/get-hospital-notifications';
const getHospitalLettersUrl = rootUrl+'/get-hospital-letters';
const updatePasswordUrl = rootUrl+'/update/password';
// Parameter module Urls
const getSiteParameterUrl = rootUrl+'/get-parameter-value';
// Payment Urls
const getVerificationPaymentUrl = rootUrl+'/hospital-verification-payment';
const generateVerificationRrrUrl = rootUrl+'/generate-assessment-rrr';
const saveVerificationTransactionUrl = rootUrl+'/save-verification-transaction';
const verificationRrrStatusUrl = rootUrl+'/verification-rrr-transaction';
const getHospitalPaymentsUrl = rootUrl+'/get-hospital-payments';
// Application Urls
const getPendingApplicationUrl = rootUrl+'/hospital-pending-application';
const updateHospitalDataUrl = rootUrl+'/update-hospital-data';
const deleteHospitalDataUrl = rootUrl+'/delete-hospital-data';
const submitVerificationApplicationUrl = rootUrl+'/submit-hospital-application';
const getHospitalApplicationsUrl = rootUrl+'/get-hospital-applications';
const getApplicationUrl = rootUrl+'/get-hospital-application';
const requestVerifyVisitUrl = rootUrl+'/request-hospital-verification-visit';
// other Urls
const loadingBladeUrl = rootUrl+'/storage/images/loading.gif';
const logoutUserUrl = rootUrl+'/logout';
const blankImageUrl = rootUrl+'/storage/images/blank-photo.png';
const completeImageUrl = rootUrl+'/storage/images/complete.jpeg';
const certificateImageUrl = rootUrl+'/storage/images/certificate.png';
const resetPasswordUrl = rootUrl+'/reset-hospital-password';
const printReceiptUrl = rootUrl+'/print-hospital-receipt';
const perPage = 20;
const recaptchaKey = '6LfIxSUaAAAAAGt9xeD2zcDuZWwcK9lv2Pe6Yu4P';
const websiteUrl = 'https://mdcn.gov.ng';
// Authorization Key
const authorizationToken = '2TcXrFhPN7qIRCEobdeZcYkqZgiSRRmYOwvjce1';
// Remita Urls & Keys
const remitaPaymentUrl ='https://login.remita.net/remita/ecomm/init.reg';
const remitaMerchantId = '632028472';
const remitaApiKey = '361049';

const state = {
    updatePasswordUrl: updatePasswordUrl,
    requestVerifyVisitUrl: requestVerifyVisitUrl,
    getHospitalLettersUrl: getHospitalLettersUrl,
    getHospitalNotificationsUrl: getHospitalNotificationsUrl,
    getHospitalPaymentsUrl: getHospitalPaymentsUrl,
    getApplicationUrl: getApplicationUrl,
    getHospitalApplicationsUrl: getHospitalApplicationsUrl,
    uploadUserPhotoUrl: uploadUserPhotoUrl,
    changePasswordUrl: changePasswordUrl,
    submitVerificationApplicationUrl: submitVerificationApplicationUrl,
    deleteHospitalDataUrl: deleteHospitalDataUrl,
    updateHospitalDataUrl: updateHospitalDataUrl,
    getPendingApplicationUrl: getPendingApplicationUrl,
    verificationRrrStatusUrl: verificationRrrStatusUrl,
    saveVerificationTransactionUrl: saveVerificationTransactionUrl,
    generateVerificationRrrUrl: generateVerificationRrrUrl,
    getHospitalsUrl: getHospitalsUrl,
    createSignupUrl: createSignupUrl,
    getSiteParameterUrl: getSiteParameterUrl,
    hospitalLoginUrl: hospitalLoginUrl,
    hospitalProfileUrl: hospitalProfileUrl,
    getVerificationPaymentUrl: getVerificationPaymentUrl,
    hospitalId: localStorage.getItem('hospital_id') || null,
    authorizationToken: authorizationToken,
    perPage: perPage,
    remitaApiKey: remitaApiKey,
    remitaPaymentUrl: remitaPaymentUrl,
    remitaMerchantId: remitaMerchantId,
    rootUrl: rootUrl,
};

const getters = {
    loadingBladeUrl: () => loadingBladeUrl,
    getLogoutUserUrl: () => logoutUserUrl,
    remitaVerificationCallBackUrl: () => remitaVerificationCallBackUrl,
    recaptchaKey: () => recaptchaKey,
    blankImageUrl: () => blankImageUrl,
    getResetPasswordUrl: () => resetPasswordUrl,
    getupdatePasswordUrl: () => updatePasswordUrl,
    hospitalPhotoUrl: state => state.rootUrl+'/storage/hospitals/hospital'+state.hospitalId+'/photo/',
    getAuthorizationToken: state => state.authorizationToken,
    getRootUrl: () => rootUrl,
    websiteUrl: () => websiteUrl,
    printReceiptUrl: () => printReceiptUrl,
    remitaApiKey: state => state.remitaApiKey,
    remitaPaymentUrl: state => state.remitaPaymentUrl,
    remitaMerchantId: state => state.remitaMerchantId,
    completeImageUrl: () => completeImageUrl,
    certificateImageUrl: () => certificateImageUrl
};

const actions = { };

const mutations = { };

export default {
    state,
    getters,
    actions,
    mutations
}