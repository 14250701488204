import axios from 'axios';

const perPage = 20;

const state = {
    accessToken: localStorage.getItem('access_token') || null,
    hospitalId: localStorage.getItem('hospital_id') || null,
    hospital: {},
    lock: false,
    uploadProgress: null,
    perPage: perPage,
    notifications: {}
};

const getters = {
    isLoggedIn: state => state.accessToken !== null && state.hospitalId !== null ? true : false,
    hospital: state => state.hospital,
    lock: state => state.hospital && (state.hospital.profile_lock == 'yes') ? true : false,
    locations: state => state.locations,
    notifications: state => state.notifications
};

const actions = {
    async getHospitals(state) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getHospitalsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        return response.data;
    },
    async createSignup(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createSignupUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async hospitalLogin(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.hospitalLoginUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    storeAndLoginUser(state, data) {//set token and login user
        let hours = 2;
        localStorage.setItem('access_token', data.access_token);// set access token
        localStorage.setItem('hospital_id', data.hospital_id);// set hospital id
        localStorage.setItem('time_out', new Date().getTime() + (hours * 60 * 60 * 1000));// set timeout
    },
    destroyAccessToken(state) {// destroy local storage
        localStorage.removeItem('access_token');// remove token
        localStorage.removeItem('hospital_id');// remove hospital id
        localStorage.removeItem('time_out');// remove time out
        return state.accessToken = null;
    },
    async fetchHospitalProfile(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.hospitalProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : state.state.hospitalId},
                { headers: header });// make api call
        state.commit('setHospital', response.data.hospital);
        return response.data.hospital;
    },
    async changePassword(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changePasswordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async uploadUserPhoto(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadUserPhotoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    updatePhotoName({ commit }, fileName) {// update photo name
        commit('setUpdatePhotoName', fileName);// set update photo function
    },
    async getHospitalNotifications(state, limit) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getHospitalNotificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : state.state.hospitalId, limit : limit }, { headers: header });// make api call
        state.commit('setHospitalNotifications', response.data);
        return response.data;
    },
    async getHospitalNotification(state, slug) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getHospitalNotificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { slug : slug }, { headers: header });// make api call
        return response.data;
    },
    async getHospitalLetters(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getHospitalLettersUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : state.state.hospitalId}, { headers: header });// make api call
        return response.data;
    },
};

const mutations = {
    setHospital: (state, hospital) => (state.hospital = hospital),// set user profile
    setUpdatePhotoName: (state, fileName) => (state.hospital.photo = fileName),// set user basic profile only
    setHospitalNotifications(state, data) {// set user notifications
        state.notifications.records = data.notifications.data;
        state.notifications.total = data.total;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}