import axios from 'axios';

const state = {

};

const getters = {
};

const actions = {
    async getVerificationPayment(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getVerificationPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : state.rootState.user.hospitalId}, { headers: header});// make api call
        return response.data;
    },
    async generateVerificationRrr(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.generateVerificationRrrUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async saveVerificationTransaction(state, orderId) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveVerificationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const hospitalId = state.rootState.user.hospitalId;
        let response = await axios.post(url, { 'order_id' : orderId, 'id' : hospitalId }, { headers: header});// make api call
        return response.data;
    },
    async verificationRrrStatus(state, rrr) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.verificationRrrStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const hospitalId = state.rootState.user.hospitalId;
        let response = await axios.post(url, { 'rrr' : rrr, 'id' : hospitalId }, { headers: header});// make api call
        return response.data;
    },
    async getHospitalPayments(state, limit) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getHospitalPaymentsUrl;
        const hospitalId = state.rootState.user.hospitalId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: hospitalId, limit : limit }, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}